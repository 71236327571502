var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "carousel", staticClass: "dp-pro-recipe-detail-carousel" },
    [
      _c("div", { staticClass: "swiper-wrapper" }, [_vm._t("default")], 2),
      _c("div", { ref: "pagination", class: _vm.paginationClasses }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }