<template>
  <div ref="carousel" class="dp-pro-recipe-detail-carousel">
    <div class="swiper-wrapper">
      <slot />
    </div>

    <div ref="pagination" :class="paginationClasses" />
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted
} from '@vue/composition-api';

// @see https://swiperjs.com/
import Swiper, { Pagination, Autoplay, EffectFade } from 'swiper';
import lodash from 'lodash';

export default defineComponent({
  props: {
    contents: {
      type: Array,
      default: undefined
    },

    options: {
      type: Object,
      default: undefined
    }
  },

  setup: (props) => {
    const carousel = ref(null);
    const pagination = ref(null);
    const swiper = ref(null);

    const paginationClasses = computed(() => [
      'dp-pro-recipe-detail-carousel-pagination',
      props.contents.length > 1
        ? false
        : 'dp-pro-recipe-detail-carousel-pagination--hidden'
    ]);

    const init = () => {
      const initOptions = {
        modules: [Pagination, Autoplay, EffectFade],
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        /* eslint-disable indent */
        autoplay:
          props.contents.length > 1
            ? {
                disableOnInteraction: false
              }
            : false,
        /* eslint-enable */

        speed: 500,
        loop: true,

        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },

        pagination: {
          clickable: false,
          el: pagination.value,
          type: 'bullets',
          bulletClass: 'dp-pro-recipe-detail-carousel-pagination__bullet',
          bulletActiveClass:
            'dp-pro-recipe-detail-carousel-pagination__bullet--active'
        }
      };
      const mergedOptions = lodash.merge(initOptions, props.options);
      swiper.value = new Swiper(carousel.value, mergedOptions);
    };

    onMounted(async () => {
      init();
    });

    return {
      carousel,
      pagination,
      paginationClasses
    };
  }
});
</script>

<style lang="scss" scoped>
@use 'swiper/modules/autoplay/autoplay.scss';
@use 'swiper/modules/effect-fade/effect-fade.scss';
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-detail-carousel {
  position: relative;
  margin: 0;

  > div {
    img {
      width: 100%;
      height: auto;
    }
  }

  .swiper-slide {
    height: auto;
  }
}

.dp-pro-recipe-detail-carousel-pagination::v-deep {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 20px;

  .dp-pro-recipe-detail-carousel-pagination__bullet {
    padding: 0 4px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &::before {
      display: block;
      content: '';
      background-color: variables.$dpGrey88;
      width: 24px;
      height: 3px;
    }

    &--active {
      &::before {
        background-color: variables.$dpGreyEa;
      }
    }
  }
}

.dp-pro-recipe-detail-carousel-pagination--hidden::v-deep {
  .dp-pro-recipe-detail-carousel-pagination__bullet {
    opacity: 0;
  }
}
</style>
